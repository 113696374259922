<script lang="ts">
	import Link from "./Link.svelte";
	import fastestLogo from "../assets/images/fastest-logo-horizontal.svg";
	import StaticPicture from "./Picture/StaticPicture.svelte";
	import type { MenuItem } from "../../../core/schema/MenuItem";
	import logo from "../../../core/assets/logo.svg";
	import { appName } from "../../../core/schema/names/appName.js";
	import Icon from "./Icon.svelte";
	import fb from "../../../core/assets/icons/fb.svg?raw";
	import ig from "../../../core/assets/icons/ig.svg?raw";
	import type { SubmenuItem } from "../../../core/schema/SubmenuItem.js";

	export let hasPanel = false;
	export let menu: MenuItem[];

	type FooterMenuItem = Extract<MenuItem, { href: string }> | SubmenuItem;
	const footerMenu: FooterMenuItem[] = [
		{
			id: "express",
			name: "Dorty na zítra k vyzvednutí",
			href: "dorty-express",
			onlyInFooter: true,
			target: "_self",
		},
		{
			id: "poukazy",
			name: "Dárkové poukazy",
			href: "darkove-poukazy",
			onlyInFooter: true,
			target: "_self",
		},
		{
			id: "caste-dotazy",
			name: "Časté dotazy",
			href: "casto-kladene-otazky",
			onlyInFooter: true,
			target: "_self",
		},
		{
			id: "obchodni-podminky",
			name: "Všeobecné obchodní podmínky",
			href: "documents/SladkáDílnaVOP.pdf",
			onlyInFooter: true,
			target: "_blank",
		},
		{
			id: "osobni-udaje",
			name: "Zásady zpracování osobních údajů",
			href: "documents/SladkáDílnaZásadyZpracováníOsobníchÚdajů.pdf",
			onlyInFooter: true,
			target: "_blank",
		},
		{
			id: "formular-odstoupeni",
			name: "Formulář pro odstoupení od smlouvy",
			href: "documents/SladkáDílnaFormulářProOdstoupeníOdSmlouvy.pdf",
			onlyInFooter: true,
			target: "_blank",
		},
		{
			id: "cookies",
			name: "Poučení o souborech cookies",
			href: "documents/SladkáDílnaCookiesPolicy.pdf",
			onlyInFooter: true,
			target: "_blank",
		},
	];

	const lists = [menu.flatMap((menuItem) => ("submenus" in menuItem ? menuItem.submenus : menuItem)), footerMenu];
</script>

<footer
	class="text-secondary overflow-hidden text-center md:text-left {hasPanel
		? 'pb-[5rem] sm:pb-[3.125rem] lg:pb-[3.875rem]'
		: ''}"
>
	<div class="relative">
		<div class="bg-secondary-100 py-16 md:py-[6.5rem]">
			<div class="container flex flex-col gap-8 md:flex-row md:gap-4">
				<div class="flex grow flex-col items-center gap-[2.6rem] md:items-start lg:basis-1/2">
					<a href="/" class="w-[4.5rem]">
						<StaticPicture
							alt="Logo {appName}"
							image={logo}
							class="w-[4.5rem] md:w-32"
							loading="lazy"
							width={128}
							height={128}
						/>
					</a>
					<div class="flex flex-col items-center gap-4 md:items-start">
						<div>
							<p class="font-semibold">Sladká dílna s.r.o.</p>
							<p>ČSA 363, Odolena Voda, 250 70</p>
						</div>
						<div>
							<p class="flex items-center gap-2">
								<span class="text-xs font-semibold">IČO</span>
								<span>14199793</span>
							</p>
							<p class="flex items-center gap-2">
								<span class="text-xs font-semibold">DIČ</span>
								<span>CZ14199793</span>
							</p>
						</div>
					</div>
					<div class="hidden justify-between gap-3 md:flex">
						<Link href="https://www.facebook.com/sladkadilna" target="_blank">
							<Icon icon={fb} class="hover:text-primary h-[1.25rem] transition-colors duration-300" />
						</Link>
						<Link
							href="https://www.instagram.com/sladkadilna?igsh=MXRhNDJ4M295b2F1Mg%3D%3D&utm_source=qr"
							target="_blank"
						>
							<Icon icon={ig} class="hover:text-primary h-[1.25rem] transition-colors duration-300" />
						</Link>
					</div>
				</div>
				<div class="flex flex-col justify-center gap-8 md:flex-row md:gap-8 lg:basis-1/2 lg:gap-4">
					{#each lists as list}
						<ul class="flex flex-col items-center md:basis-1/2 md:items-start">
							{#each list as menuItem}
								<li>
									<Link
										class="text-secondary hover:text-primary w-full whitespace-nowrap py-2 font-semibold transition-colors duration-300 sm:py-3"
										href="/{menuItem.href}"
										target={menuItem.target}
										noUnderline
									>
										{menuItem.name}
									</Link>
								</li>
							{/each}
						</ul>
					{/each}
				</div>
			</div>
			<div class="mt-5 flex justify-center gap-3 md:hidden">
				<Link href="https://www.facebook.com/sladkadilna" target="_blank">
					<Icon icon={fb} class="hover:text-primary h-[1.25rem] transition-colors duration-300" />
				</Link>
				<Link href="https://www.instagram.com/sladkadilna?igsh=MXRhNDJ4M295b2F1Mg%3D%3D&utm_source=qr" target="_blank">
					<Icon icon={ig} class="hover:text-primary h-[1.25rem] transition-colors duration-300" />
				</Link>
			</div>
		</div>
		<div class="bg-secondary-150 pb-12 pt-8">
			<div
				class="text-2xs container flex flex-col items-center gap-5 lg:flex-row lg:items-start lg:justify-between lg:text-xs"
			>
				<div class="whitespace-nowrap">
					<span class="font-semibold">&copy; 2024 Sladká dílna s.r.o.</span> - www.sladkadilna.cz
				</div>
				<div class="group flex cursor-pointer items-center gap-1">
					<span
						class="group-hover:decoration-secondary mt-1 underline decoration-transparent transition-[text_decoration] duration-300"
					>
						Upečeno ve
					</span>
					<a href="https://www.fastest.cz/" target="_blank">
						<StaticPicture image={fastestLogo} alt="Fastest Solution" height={27} width={142} loading="lazy" />
					</a>
				</div>
			</div>
		</div>
	</div>
</footer>
